<i18n>
  {
    "es": {
      "RECOMMENDED_SELL_PRICE": "PVR",
      "FROM": "Desde",
      "NEW": "Novedad",
      "SOLD_OUT": "Agotado"
    },
    "en": {
      "RECOMMENDED_SELL_PRICE": "RSP",
      "FROM": "From",
      "NEW": "Novedad",
      "SOLD_OUT": "Sold out"
    },
    "fr": {
      "RECOMMENDED_SELL_PRICE": "PVP",
      "FROM": "À partir de",
      "NEW": "Nouveauté",
      "SOLD_OUT": "Épuisé"
    },
    "de": {
      "RECOMMENDED_SELL_PRICE": "UVP",
      "FROM": "Von",
      "NEW": "Neuheit",
      "SOLD_OUT": "Ausverkauft"
    },
    "it": {
      "RECOMMENDED_SELL_PRICE": "PVP",
      "FROM": "Da",
      "NEW": "Novità",
      "SOLD_OUT": "Esaurito"
    },
    "pt": {
      "RECOMMENDED_SELL_PRICE": "PVR",
      "FROM": "De",
      "NEW": "Novidade",
      "SOLD_OUT": "Esgotado"
    }
  }
</i18n>

<template>
  <div
    class="flex flex-col items-start relative justify-center w-full bg-transparent"
  >
    <NuxtLink
      :replace="false"
      :to="convertUrlToRelative(data?.url, locale)"
      class="w-full relative"
    >
      <NuxtImg
        :alt="data?.name"
        :lazy="true"
        class="w-150 h-150 md:w-250 md:min-h-[250px] object-contain mx-auto"
        :src="data?.img"
      />

      <DiscountBadge
        v-if="
          data?.price_policy !== 'ONLY_EFFECTIVE_PRICE' &&
          data?.price_policy !== 'NO_DISCOUNT_BADGE'
        "
        :badge="data?.badge"
      />

      <div
        v-if="data?.stock <= 0"
        class="absolute -top-2 right-0 bg-[#E18295] uppercase text-white font-bold text-9 md:text-xxs w-10 h-6 xs:w-12 xs:h-6 md:w-[58px] md:h-[28px] rounded flex items-center justify-center z-10"
      >
        {{ t('SOLD_OUT') }}
      </div>

      <div
        v-if="data?.new && data?.stock > 0"
        class="absolute bg-black -top-2 right-0 uppercase text-white font-bold text-9 md:text-xxs w-10 h-6 xs:w-12 xs:h-6 md:w-[58px] md:h-[28px] rounded flex items-center justify-center z-10"
      >
        {{ t('NEW') }}
      </div>
    </NuxtLink>

    <div class="flex flex-col items-start w-full mt-8">
      <NuxtLink
        class="flex flex-col items-start w-full"
        :replace="false"
        :to="convertUrlToRelative(data?.url, locale)"
      >
        <p
          class="font-bold text-xs md:text-sm uppercase tracking-widest h-7 md:h-full text-sanremo-black"
        >
          {{ data?.manufacturer_name }}
        </p>

        <h2
          class="mt-1 text-xs md:text-sm tracking-[0.03em] font-light leading-5 md:leading-5 h-12 md:h-16 lg:h-12 xl:h-8 text-sanremo-black"
        >
          {{ data?.name }}
        </h2>

        <p
          class="mt-4 lg:mt-9 h-7 text-xxs md:text-xs font-medium text-sanremo-gray tracking-[0.03em] leading-4"
        >
          {{ data?.default_category_name }}
        </p>
        <span
          class="mt-2 lg:mt-4 h-4 text-xs md:text-sm font-normal tracking-wider text-sanremo-black"
          >{{
            data?.pvp && data?.price_policy !== 'ONLY_EFFECTIVE_PRICE'
              ? `${t('RECOMMENDED_SELL_PRICE')}:`
              : ''
          }}
          <span
            class="line-through"
            v-if="data?.pvp && data?.price_policy !== 'ONLY_EFFECTIVE_PRICE'"
            >{{ formatPrice(data?.pvp) }} {{ currency?.symbol }}</span
          ></span
        >
        <span class="mt-1 text-sm md:text-lg font-semibold text-sanremo-black">
          <span v-if="data?.price_type == 'FROM'" class="text-xs">
            {{ t('FROM') }}
          </span>
          {{ formatPrice(data?.price) }}
          {{ currency?.symbol }}
        </span>
        <div class="mt-2 min-h-[20px]">
          <span
            v-if="data?.price_ratio_quantity"
            class="mt-2 text-others-grey3 text-11 font-normal"
            >( <span>{{ data?.price_ratio_quantity }} </span> )</span
          >
        </div>
      </NuxtLink>

      <VariantPresentations
        class="mt-2"
        :productPresentation="data?.presentation"
        :criteria="data?.variant_presentations?.type"
        :values="data?.variant_presentations?.values"
        :url="convertUrlToRelative(data?.url, locale)"
      />

      <NuxtLink
        :replace="false"
        :to="convertUrlToRelative(data?.url, locale)"
        class="mt-2 flex items-center gap-0.5"
      >
        <div
          class="flex items-center gap-0.5"
          v-if="data?.rating_value != null && data?.rating_value >= 1"
        >
          <img
            v-for="star in parseInt(data?.rating_value)"
            :key="star"
            src="@/assets/svg/icons/icons-star-filled.svg"
            alt="estrella de valoración positiva"
            class="h-3 w-3"
          />

          <img
            v-for="star in 5 - parseInt(data?.rating_value)"
            :key="star"
            src="@/assets/svg/icons/icons-star-unfilled.svg"
            alt="estrella de valoración sin rellenar"
            class="w-3 h-3"
          />
        </div>
        <div class="flex items-center gap-0.5" v-else>
          <img
            v-for="star in 5"
            :key="star"
            src="@/assets/svg/icons/icons-star-unfilled2.svg"
            alt="estrella de valoración sin rellenar"
            class="w-3 h-3"
          />
        </div>
        <span class="text-sanremo-gray text-10 font-semibold">{{
          data?.rating_value != null && data?.rating_value > 0
            ? data?.rating_value
            : ''
        }}</span>
        <span
          v-if="data?.rating_count"
          class="text-sanremo-gray text-10 font-semibold"
        >
          ({{ data?.rating_count }})</span
        >
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import { convertUrlToRelative, formatPrice } from '@/utils/helpers'
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
})

const route = useRoute()
const { t, locale } = useI18n()
</script>
